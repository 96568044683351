import { Subscription } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../shared/global.service';
import moment from 'moment';
import { SettingsService } from '../shared/api/resources/settings/settings.service';
import { Customer } from '../shared/api/model/customer';
import { WidgetService } from '../shared/api/widget/widget.service';
import { StaticUtils } from '../shared/utils/static-utils';
import { Promocode } from '../shared/api/model/promocode';
import { ToasterService } from '../shared/atoms/toaster/toaster.service';
import { CartService } from '../shared/cart/cart.service';
import {Booking} from '../shared/api/model/booking';
import { BookingShoppingCart } from '../shared/api/model/BookingShoppingCart';
import { BillingDetails } from '../shared/api/model/billing-details';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {
  private _promocode = '';

  utils = StaticUtils;
  moment = moment;
  sumOfGenderPriceQuantityToBook: number | null = null;
  missingSeatsToReachedMinimumLotation: number;
  nationalities = [];
  customer: Customer;
  selectedCountry = '';
  termsAccepted = false;
  confirmEmail: string;
  emailValid = true;
  phoneValid = true;
  emailsMatch = true;
  isToHidePromocode = true as boolean;
  isPromocodesCollapsed = true;
  collapseStyles = {'overflow': 'hidden'};
  findingPromocode = false;
  promocodeNotValid = false;
  promocodeError: string;
  cart: BookingShoppingCart;
  billingDetails = new BillingDetails();
  isBillingCollapsed = true;
  billingCollapseStyles = {'overflow': 'hidden'};
  currentPromocode: string;
  shoppingCartChangeSub: Subscription;

  constructor(
    private _router: Router,
    private _globalService: GlobalService,
    private _settings: SettingsService,
    private _widgetService: WidgetService,
    private _cartService: CartService,
    private _toasterService: ToasterService) { }

  public async ngOnInit(): Promise<void> {

    if (this._globalService.skip_my_cart === true) {
      setTimeout(() => this._updateCart(true));
      this.shoppingCartChangeSub = this._cartService.shoppingCartChange.subscribe(() => this._updateCart());
    }

    this.termsAccepted = this._globalService.acceptedTerms;
    this.customer = this._globalService.customer || new Customer();
    this.confirmEmail = this._globalService.confirmEmail || '';
    this.updateData();
    this.cart = await this._cartService.getCart();
    this.currentPromocode = this.cart.attachPromocode;
    if (this.currentPromocode) {
      this.togglePromocodesCollapse();
    }
    this.isToHidePromocode = environment.isToHidePromocode;
    //this._widgetService.getInfo().subscribe(data => this._initData(data));
  }

  // private async _initData(data: any): Promise<void> {
  //   // console.log('data ----:>> ', data);
  //   this.isToHidePromocode = data.isToHidePromocode;
  // }

  private async _updateCart(forceUpdate = false): Promise<void> {
    this._globalService.loading = true;
    this.cart = await this._cartService.getCart(forceUpdate);
    this._globalService.loading = false;
  }

  public updateData(): void {
    this.nationalities = [];
    for (const country of this._settings.countries) {
      this.nationalities.push({label: country.name, value: country});
      if (this._globalService.customer && country.id === this._globalService.customer.country) {
        this.selectedCountry = country.name;
      }
    }
  }

  public async book(): Promise<void> {
    this._globalService.loading = true;
    await this.saveCustomer(true);

    if (this.cart.billingTotal === 0) {
      this._globalService.processingPayment = true;
      try {
        await this._widgetService.shoppingCartStripePaymentIntent(this.cart.id, this.cart.guid).toPromise();
        await this._router.navigate(['../thankyou/'], { 
          queryParamsHandling: 'merge' 
        });
      } catch (errorResult) {
        this._toasterService.showError(errorResult.error ? errorResult.error.message : errorResult.message);
      } finally {
        this._globalService.loading = false;
        this._globalService.processingPayment = false;
      }
    } else {
      await this._router.navigate(['../payment/'], { 
        queryParamsHandling: 'merge' 
      });
      this._globalService.loading = false;
    }
  }
  
  public showMessagePendingConfirm = (booking: Booking): boolean => {
    let event = booking.event
    let bookingGenderPrices = booking.bookingGenderPrices
    this.sumOfGenderPriceQuantityToBook = bookingGenderPrices
      .filter((gp: any) => gp.audienceType !== null) 
      .map((gp: any) => gp.quantityToBook)
      .reduce((prev: number, next: number) => prev + next, 0);
      
      const staticEvent = this._globalService.getStaticEvent(); // static because event is setted on global service to access and keep the value static of event.quantityBooked 
      let totalPaxBooked = this.sumOfGenderPriceQuantityToBook + staticEvent.quantityBooked // total pax of the booking and the pax in quantityBooked
      this.missingSeatsToReachedMinimumLotation = event.minimumLotation - staticEvent.quantityBooked

      if(totalPaxBooked < event.minimumLotation){
      return true
    } else return false
  }

  validateCustomerPhone() {
    this.phoneValid = this.validPhone(this.customer.phone);
    // this.customer.phone.match( /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/ );
  }

  validPhone(phone) {
    // const re = /[\+]?[[0-9\s]+]?/;
    const re = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
    return re.test(phone);
  }

  validateCustomerEmail() {
    this.emailValid = this.validEmail(this.customer.email);
  }

  validEmail(email) {
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(email);
  }

  matchEmails() {
    this.emailsMatch = this.customer.email.toLowerCase() === this.confirmEmail.toLowerCase();
  }

  togglePromocodesCollapse() {
    this.isPromocodesCollapsed = !this.isPromocodesCollapsed;
    this.promocodeNotValid = false;
    if (this.isPromocodesCollapsed) {
      this.collapseStyles = {'overflow': 'hidden'};
    } else {
      setTimeout(() => {
        this.collapseStyles = {'overflow': 'initial'};
      }, 500);
    }
  }

  toggleBillingCollapse() {
    this.isBillingCollapsed = !this.isBillingCollapsed;
    if (this.isBillingCollapsed) {
      this.billingCollapseStyles = {'overflow': 'hidden'};
    } else {
      setTimeout(() => {
        this.billingCollapseStyles = {'overflow': 'initial'};
      }, 500);
    }
  }

  async applyPromocode(): Promise<void> {
    this.promocodeError = undefined;
    this.promocodeNotValid = false;
    if (this._promocode) {
      try {
        const promocode = await this.findPromocode();
        if (this.isPromocodeValid(promocode)) {
          this._globalService.loading = true;
          await this._cartService.applyPromocodeToShoppingCart(this._promocode);
          this.currentPromocode = this._promocode.toUpperCase();
          // this._globalService.promocode = this.currentPromocode;
          this.cart = await this._cartService.getCart();
          this._globalService.loading = false;
        } else {
          this.promocodeNotValid = true;
        }
      } catch (e) {
        this.promocodeNotValid = true;
        this.promocodeError = e.message;
      }
      if (this.promocodeNotValid) {
        this._promocode = '';
        this.currentPromocode = undefined;
      }
    } else {
      this.promocodeNotValid = true;
      this.promocodeError = 'Promocode is empty.';
    }
  }

  async removePromocodeFromCart(): Promise<void> {
    this._globalService.loading = true;
    try {
      await this._cartService.applyPromocodeToShoppingCart('');
      this.currentPromocode = undefined;
      this.cart = await this._cartService.getCart();
    } finally {
      this._globalService.loading = false;
    }
  }

  findPromocode(): Promise<Promocode> {
    return new Promise((resolve, reject) => {
      let result: Promocode;
      this.findingPromocode = true;
      this._widgetService.findPromocode(this.cart.bookings[0].event.supplierId, this._promocode).subscribe(
        (promocode: Promocode) => result = promocode,
        (e) => {
          this.findingPromocode = false;
          reject(e.error);
        },
        () => {
          this.findingPromocode = false;
          if (result !== undefined) {
            resolve(result);
          } else {
            reject();
          }
        });
    });
  }

  isPromocodeValid(promocode: Promocode): boolean {
    let result = false;
    if (promocode) {
      const isAfterStart = moment().isAfter(moment(promocode.bookingPeriodStart));
      const isBeforeEnd = moment().isBefore(moment(promocode.bookingPeriodFinish));
      result = isAfterStart && isBeforeEnd && promocode.status === 1 && promocode.promocodeType === 0;
    }

    return result;
  }

  getPromocodeErrorMessage(): string {
    return this.promocodeError || 'Invalid code, please try again';
  }

  async saveCustomer(addToCart = false): Promise<void> {
    this._globalService.customer = this.customer;
    this._globalService.confirmEmail = this.confirmEmail;
    this._globalService.acceptedTerms = this.termsAccepted;
    if (addToCart) {
      await this._cartService.addCustomerToShoppingCart(this.customer, this.billingDetails);
    }
  }

  get isLoading(): boolean {
    return this._globalService.loading;
  }

  get promocode(): string {
    return this._promocode;
  }

  set promocode(value: string) {
    this._promocode = value;
  }

  public isToHideDate(): boolean {
    return this._globalService.hide_date;
  }
}
