<div [ngClass]="{'d-flex flex-column warning': true, 'pending': !_isToRejectBookingMinMax}" *ngIf="_hasMinLotation">
  <p>
    A minimum of <b>{{event.minimumLotation}}</b> PAX per event is required. Otherwise the booking will be pending confirmation.
    <br>Already booked on this event: {{event.quantityBooked}} PAX
  </p>
</div>
<div class="warning" *ngIf="!hasAvailableQuantity(event, true)">
  <p>
    This activity has <b>{{ event.quantityAvailable }}</b> seats available
  </p>
</div>
<div class="d-flex flex-column warning" *ngIf="_isToRejectBookingMinMax && (_reachedMaximumPax || _reachedMinimumPax)">
  <p class="mb-2" *ngIf="_reachedMaximumPax">
    This activity only allows <b>{{event.maximumPaxPerBooking}}</b> seats per booking.
  </p>
  <p class="mb-2" *ngIf="_reachedMinimumPax">
    This activity requires at least <b>{{event.minimumPaxPerBooking}}</b> seats per booking.
  </p>
</div>
<div class="d-flex flex-column warning pending" *ngIf="!_isToRejectBookingMinMax  && (_reachedMaximumPax || _reachedMinimumPax)">
  <p class="mb-2" *ngIf="_reachedMaximumPax">
    This activity only allows <b>{{event.maximumPaxPerBooking}}</b> seats per booking. If the limit is exceeded, the booking will be pending confirmation.
  </p>
  <p class="mb-2" *ngIf="_reachedMinimumPax">
    This activity requires at least <b>{{event.minimumPaxPerBooking}}</b> seats per booking. Otherwise the booking will be pending confirmation.
  </p>
</div>
<div class="warning" *ngFor="let genderPriceDeps of genderPricesDependenciesMessage(eventGenderPrices)">
  <p>{{genderPriceDeps}}</p>
</div>

<div class="event-title">
  <h4>{{ event.product.name }}</h4>
  <p *ngIf="!isToHideDate()">
    {{ utils.formatDateTime(moment(event.start)) }} ({{
      utils.formatDuration(
        moment(moment().format("YYYY-MM-DD") + " " + event.duration)
      )
    }})
  </p>
</div>

<div class="d-flex justify-content-end mr-4 pr-1" *ngIf="hasMoreThanOnePricePerGroup()">
  <i class="fa fa-info-circle smaller icon-color-blue"
     [ngbTooltip]="'This reservation has special prices depending on the number of people.'">
  </i>
</div>

<div class="pax">
  <div *ngFor="let eventGenderPrice of eventGenderPrices; let i = index">
    <div class="pax-item" *ngIf="isGenderPriceVisible(eventGenderPrice)">
      <label>
        <span>
          {{ getGenderPriceLabel(eventGenderPrice) }}
          <span *ngIf="eventGenderPrice.audienceTypeLabel"
            >&ensp;({{ eventGenderPrice.audienceTypeLabel }})</span
          >
        </span>
        <span class="d-flex justify-content-end">
          <div class="mr-2 price-changed" *ngIf="getPriceGroupOldPrice(eventGenderPrice) && hasAtLeastOneBook">{{utils.convertValueToCurrency(getPriceGroupOldPrice(eventGenderPrice))}}</div>
          <div>{{utils.convertValueToCurrency(eventGenderPrice.grossprice)}}</div>
        </span>
      </label>
      <app-counter
        [count]="eventGenderPrice.quantityToBook"
        [max]="isUnlimited(event) ? undefined : eventGenderPrice.maxAvailabilityForCurrentAudienceType"
        [reachedLimitOfAvailability]="reachedLimitOfAvailability(event.quantityAvailable, eventGenderPrices, i) || !hasAvailableQuantity(event, true)"
        [reachedMaximumPax]="reachedMaximumPax(event.maximumPaxPerBooking, event.rejectBookingBasedOnMinMax, eventGenderPrices, i)"
        [reachedMinimumPax]="reachedMinimumPax(event.minimumPaxPerBooking, eventGenderPrices, i)"
        [isToRejectBookingMinMax]="isToRejectBookingMinMax(event.rejectBookingBasedOnMinMax)"
        [hasMinLotation]="hasMinLotation(event)"
        [reachedMaximumPaxAndIsBlocking]="reachedMaximumPaxAndIsBlocking(event.maximumPaxPerBooking, event.rejectBookingBasedOnMinMax, eventGenderPrices)"
        (onChange)="updateQuantity(i, $event)"
      >
      </app-counter>
    </div>
  </div>
</div>

<div class="total-price">
  <app-loading *ngIf="calculatingPrice"></app-loading>
  <p *ngIf="!calculatingPrice">Total</p>
  <p *ngIf="!calculatingPrice">{{ totalPrice }}</p>
</div>
<div class="submit-container">
  <button
    class="btn"
    (click)="goToSummary()"
    [disabled]="
      !booking ||
      calculatingPrice ||
      totalQuantity === 0 ||
      (newQuantityAvailable < 0 && hasLotation) ||
      (_reachedMinimumPax && _isToRejectBookingMinMax) || 
      _hasGenderPriceDepsActive
    "
  >
    Next
  </button>
</div>
