import { EventEmitter, Injectable } from '@angular/core';
import { WidgetService } from '../api/widget/widget.service';
import { GlobalService } from '../global.service';
import { Booking } from '../api/model/booking';
import { ToasterService } from '../atoms/toaster/toaster.service';
import { BookingShoppingCart } from '../api/model/BookingShoppingCart';
import { BillingDetails } from '../api/model/billing-details';
import { Customer } from '../api/model/customer';
import { GoogleAnalyticsService } from '../../google-analytics.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  shoppingCartChange = new EventEmitter<BookingShoppingCart>();
  private _shoppingCart: BookingShoppingCart;

  constructor(
    private _widgetService: WidgetService,
    private _globalService: GlobalService,
    private _toasterService: ToasterService,
    private gtmService: GoogleTagManagerService,
    private googleAnalyticsService: GoogleAnalyticsService) { }

  async addBookingToCart(booking: Booking): Promise<void> {
    const bookings = this.getCartBookings();
    bookings.push(booking);
    this._globalService.setStaticEvent(booking.event);

    const result = await this._widgetService.addBookingToShoppingCart(
      booking,
      this._shoppingCart ? this._shoppingCart.id : undefined,
      this._shoppingCart ? this._shoppingCart.guid : undefined
    ).toPromise();
    this._shoppingCart = result.bookingShoppingCart;

    if (this._shoppingCart.bookings === null) {
      this._shoppingCart.bookings = bookings;
    }

    this.shoppingCartChange.emit(this._shoppingCart);

    const bookings_list = this._shoppingCart['bookings'];
    const last_booking = bookings_list[bookings_list.length - 1];

    if (!this._globalService.calculatedPrice) {
      const bookingCalculation: any = await this._widgetService.calculatePrice(booking).toPromise();
      this._globalService.calculatedPrice = bookingCalculation;
    }

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'add_to_cart',
      'lastProductAddedToCart': [{
        'name': last_booking.event.product.name,
        'id': last_booking.event.product.id.toString(),
        'price': this._globalService.calculatedPrice.grandTotal
      }]
    });
  }

  async updateCart(): Promise<void> {
    const result = await this._widgetService.resumeCheckout(
      this._shoppingCart ? this._shoppingCart.id : undefined,
      this._shoppingCart ? this._shoppingCart.guid : undefined
    ).toPromise();

    this._shoppingCart = result.bookingShoppingCart;
    this.shoppingCartChange.emit(this._shoppingCart);
  }

  async removeBookingFromCart(booking: Booking) {
    if (this._shoppingCart) {
      await this._widgetService.deleteBookingFromShoppingCart(booking, this._shoppingCart.id, this._shoppingCart.guid).toPromise();
      await this.updateCart();
    }
  }

  async applyPromocodeToShoppingCart(promocode: string) {
    if (this._shoppingCart) {
      await this._widgetService.applyPromocodeToShoppingCart(this._shoppingCart.id, this._shoppingCart.guid, promocode).toPromise();
      await this.updateCart();
    }
  }

  async addCustomerToShoppingCart(customer: Customer, billing = new BillingDetails()) {
    if (this._shoppingCart) {
      await this._widgetService.addCustomerToShoppingCart(this._shoppingCart.id, this._shoppingCart.guid, customer, billing).toPromise();
      await this.updateCart();
    }
  }

  async getCart(forceUpdate = false): Promise<BookingShoppingCart> {
    if (forceUpdate) {
      await this.updateCart();
    }
    return this._shoppingCart;
  }

  getCartBookingsAmount(): number {
    return this.getCartBookings().length;
  }

  getCartBookings(): Booking[] {
    return this._shoppingCart && this._shoppingCart.bookings ? this._shoppingCart.bookings : [];
  }

  clearCart(): void {
    this._shoppingCart = undefined;
    this.shoppingCartChange.emit(this._shoppingCart);
  }
}
