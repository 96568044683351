import { Component, OnInit } from "@angular/core";
import { GenderPrice } from "../shared/api/model/gender-price";
import { GlobalService } from "../shared/global.service";
import { Booking } from "../shared/api/model/booking";
import { Subscription } from "rxjs";
import { WidgetService } from "../shared/api/widget/widget.service";

import { Product } from "../shared/api/model/product";
import { Event } from "../shared/api/model/event";
import { Router } from "@angular/router";
import { BookingCalculation } from "../shared/api/model/booking-calculation";
import { StaticUtils } from "../shared/utils/static-utils";
import { CartService } from "../shared/cart/cart.service";
import { ToasterService } from "../shared/atoms/toaster/toaster.service";
import moment from "moment";

@Component({
  selector: "app-pax",
  templateUrl: "./pax.component.html",
  styleUrls: ["./pax.component.scss"],
})
export class PaxComponent implements OnInit {
  utils = StaticUtils;
  eventGenderPrices: GenderPrice[];
  calculatedPriceSub: Subscription;
  calculatingPrice = false;
  product: Product;
  event: Event;
  _isToRejectBookingMinMax: boolean = false;
  _reachedMinimumPax: boolean = false;
  _reachedMaximumPax: boolean = false;
  _hasMinLotation: boolean = false;
  _hasGenderPriceDepsActive: boolean = false;
  _reachedLimitOfAvailability: boolean = false;
  _sumOfGenderPriceQuantityToBook: number | null = null;
  _newQuantityAvailable: number | null = null;
  moment = moment;

  constructor(
    private _router: Router,
    private _globalService: GlobalService,
    private _widgetService: WidgetService,
    private _toasterService: ToasterService,
    private _cartService: CartService,
  ) {}

  ngOnInit() {
    this.event = this._globalService.event;
    this.product = this._globalService.product;
    this.eventGenderPrices = this._globalService.eventGenderPrices;

    for (let i = 0; i < this.eventGenderPrices.length; i++) {
      if (this.eventGenderPrices[i].audienceTypeOccupancy >= 2) {
        this.eventGenderPrices[i].maxAvailabilityForCurrentAudienceType =
          Math.floor(
            this.event.maximumLotation /
              this.eventGenderPrices[i].audienceTypeOccupancy
          );
      } else {
        this.eventGenderPrices[i].maxAvailabilityForCurrentAudienceType =
          this.event.maximumLotation; // start with event.maximumLotation. Will be changed later.
      }
    }

    this._newQuantityAvailable = this.event.quantityAvailable - this._globalService.totalPax();

    if (this._globalService.booking) {
      this._globalService.booking.bookingComponents = undefined;
      this.updatePrice();
    }
  }

  public async goToSummary(): Promise<void> {
    if (
      (this.event.widgetHaveAccessExtras && this._globalService.hasExtras()) ||
      (this.event.widgetHaveAccessPickups && this._globalService.hasTransport())
    ) {
      await this._router.navigate(["../add/"], {
        queryParamsHandling: "merge",
      });
      this._globalService.selectedTransportComponent = undefined
    } else if (this.event.bookingQuestionIsActive) {
      await this._router.navigate(["../question/"], {
        queryParamsHandling: "merge",
      });
    } else {
      try {
        this._globalService.loading = true;
        await this._cartService.addBookingToCart(this.booking);
        this._globalService.clearBookingProductAndEvent();
        (await this._globalService.skip_my_cart) === true
          ? this._router.navigate(["../summary/"], {
              queryParamsHandling: "merge",
            })
          : this._router.navigate(["../cart/"], {
              queryParamsHandling: "merge",
            });
      } catch (e) {
        this._toasterService.showError(e);
      } finally {
        this._globalService.loading = false;
      }
    }
  }

  public reachedMaximumPaxAndIsBlocking = (maximumPaxPerBooking: number, rejectBookingBasedOnMinMax:boolean, eventGenderPrices: any): boolean => {
    this._sumOfGenderPriceQuantityToBook = eventGenderPrices
      .filter((gp: any) => gp.audienceType !== null) 
      .map((gp: any) => gp.quantityToBook)
      .reduce((prev: any, next: any) => {
        return prev + next; // sum of all quantity to book values in the array
    });

    if (!rejectBookingBasedOnMinMax) {
      return false;
    }
    
    if (maximumPaxPerBooking !== 0 && maximumPaxPerBooking === this._sumOfGenderPriceQuantityToBook) {
        return true;
    }
    
    return false;
  }

  public genderPricesDependenciesMessage(genderPrices: any) {
    let validationMessages: Array<string> = [];
    
    if (!genderPrices) {
        return [];
    }

    genderPrices.forEach((price: {quantityToBook: number; genderPriceDependencies: any[]; audienceTypeDescription: any;}) => {
        if (price.quantityToBook <= 0) {
            return;
        }

        if (!price.genderPriceDependencies || price.genderPriceDependencies.length === 0) {
            return;
        }

        price.genderPriceDependencies.forEach(dependency => {
            const dependentPrice = genderPrices.find((p: {
                audienceType: any;
                audienceTypeGroup: any;
            }) =>
                p.audienceType === dependency.genderPriceDependsOn.audienceType &&
                p.audienceTypeGroup === dependency.genderPriceDependsOn.audienceTypeGroup
            );

            if (!dependentPrice || dependentPrice.quantityToBook < dependency.quantity) {
                const dependentDescription = this.getAudienceTypeDescription(
                    genderPrices,
                    dependency.genderPriceDependsOn.audienceType,
                    dependency.genderPriceDependsOn.audienceTypeGroup
                );

                const errorMessage = `The "${price.audienceTypeDescription}" requires at least ${dependency.quantity} "${dependentDescription}" in the reservation.`;
                validationMessages.push(errorMessage);
            }
        });
    });

    this._hasGenderPriceDepsActive = validationMessages.length > 0;

    return validationMessages;
  }

  // Find the description corresponding to audienceType
  getAudienceTypeDescription(genderPrices: any[], audienceType: any, audienceTypeGroup: any) {
    const price = genderPrices.find((p: { audienceType: any; audienceTypeGroup: any; }) => p.audienceType === audienceType && p.audienceTypeGroup === audienceTypeGroup);

    return price ? price.audienceTypeDescription : "Unknown Group";
  }

  public updateQuantity(index: number, value: number): void {
    if (this.calculatedPriceSub) {
      this.calculatedPriceSub.unsubscribe();
    }
    if (this.isGenderPriceVisible(this.eventGenderPrices[index])) {
      if (this.eventGenderPrices[index]) {
        this.eventGenderPrices[index].quantityToBook = value;
        this._newQuantityAvailable = this.event.quantityAvailable - this._globalService.totalPax();

        for (let i = 0; i < this.eventGenderPrices.length; i++) {
          if (i !== index) {
            if (this.eventGenderPrices[i].audienceTypeOccupancy >= 2) {
              // 17 / 2
              this.eventGenderPrices[i].maxAvailabilityForCurrentAudienceType =
                Math.floor(
                  this._newQuantityAvailable /
                    this.eventGenderPrices[i].audienceTypeOccupancy
                ) + this.eventGenderPrices[i].quantityToBook;
            } else {
              this.eventGenderPrices[i].maxAvailabilityForCurrentAudienceType =
                this._newQuantityAvailable +
                this.eventGenderPrices[i].quantityToBook;
            }
          }
        }
      }

      this._globalService.eventGenderPrices = this.eventGenderPrices;

      const booking = new Booking();
      booking.bookingGenderPrices = this.eventGenderPrices;
      booking.event = this._globalService.event;
      this._globalService.booking = booking;
      this.updatePrice();
    }
  }

  public updatePrice(): void {
    this.calculatingPrice = true;
    this.calculatedPriceSub = this._widgetService
      .calculatePrice(this.booking)
      .subscribe(
        (bookingCalculation: BookingCalculation) => {
          this._globalService.calculatedPrice = bookingCalculation;
          if (this._globalService.booking) {
            this._globalService.booking.calculationsGUID =
              bookingCalculation.calculationsGUID;
          }
        },
        undefined,
        () => (this.calculatingPrice = false)
      );
  }

  public getGenderPriceLabel(genderPrice: GenderPrice): string {
    return genderPrice.audienceTypeDescription || "Tickets";
  }

  public isGenderPriceVisible(genderPrice: GenderPrice): boolean {
    return (
      genderPrice.genderPriceType !== 1 ||
      (genderPrice.genderPriceType === 1 && genderPrice.isExcludedFromBilling)
    );
  }

  public hasAvailableQuantity(event: Event, atLeastOne = false): boolean {
    return (
      this.isUnlimited(event) ||
      (atLeastOne
        ? this.totalQuantity < event.quantityAvailable
        : this.totalQuantity <= event.quantityAvailable)
    );
  }

  public isUnlimited(event: Event): boolean {
    return (
      event.availabilityType === 1 ||
      event.productAvailabilityTypeDescription === "Unlimited" ||
      event.hasLotation === false ||
      event.quantityAvailable === -1 ||
      event.maximumLotation === -1
    );
  }
  public isToRejectBookingMinMax( rejectBookingBasedOnMinMax:boolean): void {
    this._isToRejectBookingMinMax = rejectBookingBasedOnMinMax
  }
  
  public reachedMinimumPax = (minimumPaxPerBooking: number, eventGenderPrices: any, i: number): void => {
    this._sumOfGenderPriceQuantityToBook = eventGenderPrices
      .filter((gp: any) => gp.audienceType !== null) 
      .map((gp: any) => gp.quantityToBook)
      .reduce((prev: any, next: any) => {
        return prev + next; // sum of all quantity to book values in the array
      });

      this._reachedMinimumPax = minimumPaxPerBooking !== 0 && 
        minimumPaxPerBooking > this._sumOfGenderPriceQuantityToBook;
  };
  public reachedMaximumPax = (
    maximumPaxPerBooking: number,
    rejectBookingBasedOnMinMax:boolean,
    eventGenderPrices: any,
    i: number
  ): boolean => {
    this._sumOfGenderPriceQuantityToBook = eventGenderPrices
      .filter((gp: any) => gp.audienceType !== null) 
      .map((gp: any) => gp.quantityToBook)
      .reduce((prev: any, next: any) => {
        return prev + next; // sum of all quantity to book values in the array
      });
      if(rejectBookingBasedOnMinMax){
        if (maximumPaxPerBooking === this._sumOfGenderPriceQuantityToBook && maximumPaxPerBooking !== 0) {
          this._reachedMaximumPax= true;
        } else {
          this._reachedMaximumPax = false;
        }
      } else if (maximumPaxPerBooking < this._sumOfGenderPriceQuantityToBook && maximumPaxPerBooking !== 0) {
        this._reachedMaximumPax= true;
      } else {
        this._reachedMaximumPax = false;
      }

    return this._reachedMaximumPax;
  }
  public reachedLimitOfAvailability = (quantityAvailable: number, eventGenderPrices: any, i: number): void => {
    this._sumOfGenderPriceQuantityToBook = eventGenderPrices
      .filter((gp: any) => gp.audienceType !== null) // discard those without audience type defined
      .map((gp: any) => gp.quantityToBook)
      .reduce((prev: any, next: any) => {
        return prev + next; // sum of all quantity to book values in the array
      });

      this._reachedLimitOfAvailability = quantityAvailable === this._sumOfGenderPriceQuantityToBook || 
        eventGenderPrices[i].audienceTypeOccupancy > this._newQuantityAvailable;
  };

  public hasMinLotation = (event: any,): any => {
    const { minimumLotation, quantityBooked } = event;
    
    const sumOfGenderPriceQuantityToBook = event.eventGenderPrices
      .filter((gp: any) => gp.audienceType !== null)
      .map((gp: any) => gp.quantityToBook)
      .reduce((prev: number, next: number) => prev + next, 0);

    // Event minimuns validation
    this._hasMinLotation = minimumLotation !== 0 &&
      minimumLotation > (sumOfGenderPriceQuantityToBook + quantityBooked);
  };

  get booking(): Booking {
    return this._globalService.booking;
  }

  get totalPrice(): string {
    return StaticUtils.convertValueToCurrency(
      this._globalService.calculatedPrice
        ? this._globalService.calculatedPrice.grandTotal
        : 0
    );
  }

  get totalQuantity(): number {
    let result = 0;
    for (const eventGenderPrice of this.eventGenderPrices) {
      if (this.isGenderPriceVisible(eventGenderPrice)) {
        if (eventGenderPrice.audienceTypeOccupancy >= 2) {
          result +=
            eventGenderPrice.audienceTypeOccupancy *
            eventGenderPrice.quantityToBook;
        } else {
          result += eventGenderPrice.quantityToBook;
        }
      }
    }
    return result;
  }

  get newQuantityAvailable(): number {
    return this._newQuantityAvailable;
  }

  get hasLotation(): boolean {
    return this.event.hasLotation;
  }

  get sumOfGenderPriceQuantityToBook(): number {
    return this._sumOfGenderPriceQuantityToBook;
  }

  public isToHideDate(): boolean {
    return this._globalService.hide_date;
  }
}
